(function() {
  var Monster, MonsterViewer,
    bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
    modulo = function(a, b) { return (+a % (b = +b) + b) % b; };

  MonsterViewer = (function() {
    function MonsterViewer() {
      this.paper = new paper.PaperScope();
      this.container = document.getElementById('monster-container');
      this.canvas = document.getElementById('monster');
      this.paper.setup(this.canvas);
      this.width = 100 + 100 * Math.random();
      this.height = 150 + 100 * Math.random();
      this.canvas.style.transform = 'scale(0)';
      this.paper.view.viewSize.width = this.width;
      this.container.style.width = "calc(" + this.width + "px + " + (Math.random() * 20 + 2) + "%)";
      window.addEventListener('throttledScroll', (function(_this) {
        return function() {
          if ((document.documentElement.scrollTop || document.body.scrollTop) + window.innerHeight > document.body.scrollHeight - 50) {
            return _this.show();
          }
        };
      })(this));
    }

    MonsterViewer.prototype.show = function() {
      var monster, y;
      if (this.visible) {
        return;
      }
      this.visible = true;
      window.monster = monster = new Monster(this.paper, this.width, this.height);
      y = this.paper.view.bounds.height / 2 - monster.height / 2 + monster.footHeight * 2 / 3;
      y = Math.max(monster.height / 2, y);
      this.canvas.style.transformOrigin = "50% " + ((y + monster.height / 2) / this.paper.view.bounds.height * 100) + "%";
      monster.group.position.y = y;

      /*Velocity(
      			e: @canvas
      			p:
      				translateY: ['25%', '100%']
      				rotateZ: '-2deg'
      			o:
      				duration: 1000
      				easing: false
      		)
      		
      		Velocity(
      			e: @canvas
      			p:
      				translateY: ['20%','25%']
      				rotateZ: '8deg'
      			o:
      				duration: 1000
      				easing: false
      		)
      		
      		Velocity(
      			e: @canvas
      			p:
      				translateY: ['0%','20%']
      				rotateZ: '10deg'
      			o:
      				duration: 1000
      				easing: false
      		)
       */

      /*Velocity(
      			e: @canvas
      			p:
      				tween: [1, 0]
      				#rotateZ: '-2deg'
      			o:
      				duration: 400
      				easing: false
      				progress: (elements, complete, remaining, start, tweenValue) ->
      					v = 1 - (tweenValue * 2 - 1)**2
      					elements[0].style.transform = "translateY(#{-4*v}%)translateZ(#{-1000+800*tweenValue}px)scaleY(#{1+0.1*v})rotateZ(#{10*v}deg)"
      					console.log complete,tweenValue,v
      					#console.log((complete * 100) + "%");
      					#console.log(remaining + "ms remaining!");
      					#console.log("The current tween value is " + tweenValue)
      		)
      		
      		Velocity(
      			e: @canvas
      			p:
      				tween: [1, 0]
      				#rotateZ: '-2deg'
      			o:
      				duration: 300
      				delay: 200
      				easing: false
      				progress: (elements, complete, remaining, start, tweenValue) ->
      					v = 1 - (tweenValue * 2 - 1)**2
      					elements[0].style.transform = "translateY(#{-4*v}%)translateZ(#{-200+150*tweenValue}px)scaleY(#{1+0.1*v})rotateZ(#{-2*v}deg)"
      					console.log complete,tweenValue,v, elements[0].style.transform
      					#console.log((complete * 100) + "%");
      					#console.log(remaining + "ms remaining!");
      					#console.log("The current tween value is " + tweenValue)
      		)
      		
      		Velocity(
      			e: @canvas
      			p:
      				tween: [1, 0]
      				#rotateZ: '-2deg'
      			o:
      				duration: 200
      				easing: false
      				progress: (elements, complete, remaining, start, tweenValue) ->
      					v = 1 - (tweenValue * 2 - 1)**2
      					elements[0].style.transform = "translateY(#{-4*v}%)translateZ(#{-50+50*tweenValue}px)scaleY(#{1+0.05*v})rotateZ(#{3*v}deg)"
      					console.log complete,tweenValue,v, elements[0].style.transform
      					#console.log((complete * 100) + "%");
      					#console.log(remaining + "ms remaining!");
      					#console.log("The current tween value is " + tweenValue)
      		)
       */

      /*Velocity(
      			e: @canvas
      			p:
      				scale: [0.4, 0.3]
      				#rotateZ: '8deg'
      			o:
      				duration: 1000
      				easing: false
      		)
      		
      		Velocity(
      			e: @canvas
      			p:
      				scale: [1, 0.4]
      				#rotateZ: '10deg'
      			o:
      				duration: 1000
      				easing: false
      		)
       */
      if (Math.random() > 0.5) {
        this.doClimbAnimation();
      } else {
        this.doWalkinAnimation();
      }
      return window.a = this;
    };

    MonsterViewer.prototype.doWalkinAnimation = function() {
      var duration, i, j, k, numbers, ref, ref1, results, smallestStep, stepCount, stepDuration, steps, sum, totalDistance;
      stepDuration = 200;
      stepCount = Math.ceil(Math.random() * 4) + 2;
      duration = stepCount * stepDuration * (Math.random() * 0.2 + 1);
      steps = [
        {
          translateZ: 0
        }
      ];
      totalDistance = 1000;
      numbers = [];
      sum = 0;
      for (i = j = 1, ref = stepCount; 1 <= ref ? j <= ref : j >= ref; i = 1 <= ref ? ++j : --j) {
        numbers[i] = Math.random();
        sum += numbers[i];
      }
      smallestStep = numbers.indexOf(Math.min.apply(Math, numbers.slice(1)));
      results = [];
      for (i = k = 1, ref1 = stepCount; 1 <= ref1 ? k <= ref1 : k >= ref1; i = 1 <= ref1 ? ++k : --k) {
        results.push((function(_this) {
          return function(i) {
            var delta, extraRotate, jump, peekRotate, previous, previousStep, rotate, scaleContinuousBegin, scaleContinuousDelta, scaleContinuousEnd, step, stretch, target;
            previousStep = steps[i - 1];
            step = {
              translateZ: i === stepCount ? 1 : numbers[i] / sum + previousStep.translateZ
            };
            previous = previousStep.translateZ;
            target = step.translateZ;
            delta = target - previous;
            jump = delta * 40;
            stretch = delta * 0.3;
            rotate = delta * 30 * (Math.random() * 0.8 + 0.2) * (Math.random() > 0.5 ? 1 : -1);
            extraRotate = i === stepCount ? Math.random() * 20 - 10 : 0;
            peekRotate = 10 * (Math.random() * 0.8 + 0.2) * (Math.random() > 0.5 ? 1 : -1);
            scaleContinuousEnd = 1;
            scaleContinuousBegin = i === 1 ? 0 : 1;
            scaleContinuousDelta = scaleContinuousEnd - scaleContinuousBegin;
            steps.push(step);
            if (i === smallestStep && i <= Math.ceil(stepCount / 3 * 2) && i > 1) {
              Velocity({
                e: _this.canvas,
                p: {
                  tween: [1, 0]
                },
                o: {
                  duration: Math.random() * duration / 2 + stepDuration * 6,
                  easing: false,
                  progress: function(elements, complete, remaining, start, tweenValue) {
                    var a, v;
                    v = 1 - Math.pow(tweenValue * 2 - 1, 2);
                    a = v;
                    v = awe.easing.easeInOutExpo(0, v, 0, 1, 1);
                    return elements[0].style.transform = "translateZ(" + (previous * totalDistance - totalDistance) + "px)scaleY(" + (1 + peekRotate / 50 * v) + ")";
                  }
                }
              });
            }
            return Velocity({
              e: _this.canvas,
              p: {
                tween: [1, 0]
              },
              o: {
                duration: numbers[i] / sum * duration,
                easing: false,
                progress: function(elements, complete, remaining, start, tweenValue) {
                  var v;
                  v = 1 - Math.pow(tweenValue * 2 - 1, 2);
                  return elements[0].style.transform = "translateY(" + (-jump * v) + "%)translateZ(" + ((delta * tweenValue + previous) * totalDistance - totalDistance) + "px)scaleY(" + ((1 + stretch * v) * (scaleContinuousDelta * tweenValue + scaleContinuousBegin)) + ")rotateZ(" + (rotate * v + extraRotate * tweenValue) + "deg)";
                }
              }
            });
          };
        })(this)(i));
      }
      return results;
    };

    MonsterViewer.prototype.doClimbAnimation = function() {
      var duration, i, j, k, numbers, ref, ref1, results, smallestStep, stepCount, stepDuration, steps, sum, totalDistance;
      stepDuration = 400;
      stepCount = Math.ceil(Math.random() * 2) + 2;
      duration = stepCount * stepDuration * (Math.random() * 0.2 + 1);
      steps = [
        {
          translateY: 0
        }
      ];
      totalDistance = -60;
      numbers = [];
      sum = 0;
      for (i = j = 1, ref = stepCount; 1 <= ref ? j <= ref : j >= ref; i = 1 <= ref ? ++j : --j) {
        numbers[i] = Math.random();
        sum += numbers[i];
      }
      smallestStep = numbers.indexOf(Math.min.apply(Math, numbers.slice(1)));
      results = [];
      for (i = k = 1, ref1 = stepCount; 1 <= ref1 ? k <= ref1 : k >= ref1; i = 1 <= ref1 ? ++k : --k) {
        results.push((function(_this) {
          return function(i) {
            var delta, jump, peekRotate, previous, previousStep, rotate, rotateDelta, rotatePrevious, rotateTarget, scaleContinuousBegin, scaleContinuousDelta, scaleContinuousEnd, step, stretch, target;
            previousStep = steps[i - 1];
            step = {
              translateY: i === stepCount ? 1 : numbers[i] / sum + previousStep.translateY,
              rotate: Math.random() * 20 - 10
            };
            previous = previousStep.translateY;
            target = step.translateY;
            delta = target - previous;
            jump = delta * 40;
            stretch = delta * 0.1;
            rotate = 0;
            rotatePrevious = previousStep.rotate;
            rotateTarget = step.rotate;
            rotateDelta = rotateTarget - rotatePrevious;
            peekRotate = 10 * (Math.random() * 0.8 + 0.2) * (Math.random() > 0.5 ? 1 : -1);
            scaleContinuousEnd = 1;
            scaleContinuousBegin = 1;
            scaleContinuousDelta = scaleContinuousEnd - scaleContinuousBegin;
            steps.push(step);
            if (i === smallestStep && i < stepCount && i > 1) {
              Velocity({
                e: _this.canvas,
                p: {
                  tween: [1, 0]
                },
                o: {
                  duration: Math.random() * duration / 2 + stepDuration * 6,
                  easing: false,
                  progress: function(elements, complete, remaining, start, tweenValue) {
                    var a, v;
                    v = 1 - Math.pow(tweenValue * 2 - 1, 2);
                    a = v;
                    v = awe.easing.easeInOutExpo(0, v, 0, 1, 1);
                    return elements[0].style.transform = "translateY(" + (previous * totalDistance - totalDistance) + "%)scaleY(" + (1 + peekRotate / 50 * v) + ")rotateZ(" + rotatePrevious + "deg)";
                  }
                }
              });
            }
            return Velocity({
              e: _this.canvas,
              p: {
                tween: [1, 0]
              },
              o: {
                duration: Math.max(numbers[i] / sum * duration, 100),
                easing: false,
                progress: function(elements, complete, remaining, start, tweenValue) {
                  var v;
                  v = 1 - Math.pow(tweenValue * 2 - 1, 2);
                  return elements[0].style.transform = "translateY(" + ((delta * tweenValue + previous) * totalDistance - totalDistance) + "%)scaleY(" + ((1 + stretch * v) * (scaleContinuousDelta * tweenValue + scaleContinuousBegin)) + ")rotateZ(" + (rotateDelta * tweenValue + rotatePrevious) + "deg)";
                }
              }
            });
          };
        })(this)(i));
      }
      return results;
    };

    return MonsterViewer;

  })();

  window.MonsterViewer = MonsterViewer;

  document.addEventListener("DOMContentLoaded", function() {
    var base;
    if (window.aweSite) {
      return (base = window.aweSite).monsterViewer || (base.monsterViewer = new MonsterViewer());
    }
  });

  Monster = (function() {
    function Monster(paper1, width, height1) {
      var blink, bottomLeft, bottomRight, eyeOffset, eyeSocketPosition, getEyeTarget, getLowerFaceTarget, height, i, pupilOffset, teeth, tooth, toothShape;
      this.paper = paper1;
      this.width = width;
      this.height = height1;
      this.frame = bind(this.frame, this);
      this.group = new this.paper.Group();
      this.group.applyMatrix = false;
      this.width || (this.width = 70 + 120 * Math.random());
      this.height || (this.height = 120 + 100 * Math.random());
      this.footHeight = 40;
      this.bodyCornerRadius = 20;
      this.shadowHeight = 20;
      this.teeth = [1, 1, 1, 1, 1, 1, 1, 1];
      this.shadowColor = new this.paper.Color(0, 0, 0, 0.15);
      this.bodyColor = new this.paper.Color({
        hue: 155,
        saturation: 1,
        brightness: 68 / 100
      });
      this.headColor = new this.paper.Color(255 / 255, 219 / 255, 87 / 255);
      this.mouthColor = new this.paper.Color(0, 134 / 255, 187 / 255);

      /*if Math.random() > .5
      			@bodyColor = new @paper.Color(0, 174/255, 102/255)
      			@headColor = new @paper.Color(255/255, 219/255, 87/255)
      			@mouthColor = new @paper.Color(0, 134/255, 187/255)
      		else
      			@bodyColor = new @paper.Color(0, 174/255, 102/255)
      			@headColor = new @paper.Color(255/255, 219/255, 87/255)
      			@mouthColor = new @paper.Color(0, 134/255, 187/255)
       */
      this.eyeColor = this.mouthColor;
      this.torsoHeight = this.height - this.footHeight / 2;
      this.faceHeight = this.torsoHeight - 40 - 20 * Math.random();
      this.foreheadHeight = Math.max(this.bodyCornerRadius, this.faceHeight * (0.5 + 0.3 * Math.random()));
      this.eyeHeight = Math.min(this.foreheadHeight * (0.5 + 0.2 * Math.random()), this.width * 0.25);
      this.pupilHeight = this.eyeHeight * 0.5 + 0.2 * Math.random();
      this.eyeSocketExtra = Math.min((this.foreheadHeight - this.eyeHeight) * (0.6 + 0.2 * Math.random()), this.eyeHeight * 1);
      this.eyeSocketHeight = this.eyeHeight + this.eyeSocketExtra;
      this.eyeSocketWidth = Math.min(this.width * (0.8 + 0.1 * Math.random()), this.eyeSocketHeight * 2 + this.eyeSocketExtra * 3);
      this.mouthHeight = (this.faceHeight - this.foreheadHeight) * (0.8 + 0.1 * Math.random());
      this.toothHeight = Math.min(this.mouthHeight / 2, this.width / this.teeth.length * (0.8 + 0.1 * Math.random()));
      this.chinHeight = this.faceHeight - this.foreheadHeight - this.mouthHeight;
      this.size = new this.paper.Size(this.width, this.height);
      this.footSize = new this.paper.Size(this.footHeight, this.footHeight);
      this.torsoSize = new this.paper.Size(this.width, this.torsoHeight);
      this.bodyCornerSize = new this.paper.Size(this.bodyCornerRadius, this.bodyCornerRadius);
      this.shadowSize = new this.paper.Size(this.size.width, this.shadowHeight);
      this.shadowCornerSize = new this.paper.Size(this.shadowSize.height / 2, this.shadowSize.height / 2);
      this.faceSize = new this.paper.Size(this.width, this.faceHeight);
      this.foreheadSize = new this.paper.Size(this.width, this.foreheadHeight);
      this.eyeSize = new this.paper.Size(this.eyeHeight, this.eyeHeight);
      this.pupilSize = new this.paper.Size(this.pupilHeight, this.pupilHeight);
      this.eyeSocketSize = new this.paper.Size(this.eyeSocketWidth, this.eyeSocketHeight);
      this.mouthSize = new this.paper.Size(this.width, this.mouthHeight);
      this.chinSize = new this.paper.Size(this.width, this.chinHeight);
      this.body = new this.paper.Path();
      bottomLeft = new this.paper.Point([0, this.torsoSize.height]);
      bottomRight = new this.paper.Point([this.torsoSize.width, this.torsoSize.height]);
      this.body.add(bottomLeft);
      this.body.add(new this.paper.Segment(new this.paper.Point(0, 0).add([0, this.bodyCornerSize.height]), null, [0, -this.bodyCornerSize.height * 0.55]));
      this.body.add(new this.paper.Segment(new this.paper.Point(0, 0).add([this.bodyCornerSize.width, 0]), [-this.bodyCornerSize.width * 0.55, 0]));
      this.body.add(new this.paper.Segment(new this.paper.Point(this.torsoSize.width, 0).subtract([this.bodyCornerSize.width, 0]), null, [this.bodyCornerSize.width * 0.55, 0]));
      this.body.add(new this.paper.Segment(new this.paper.Point(this.torsoSize.width, 0).add([0, this.bodyCornerSize.height]), [0, -this.bodyCornerSize.height * 0.55]));
      this.body.add(bottomRight);
      this.body.join(new this.paper.Path.Arc(bottomRight, bottomRight.add([-this.footSize.width / 2, this.footSize.height / 2]), bottomRight.subtract([this.footSize.width, 0])));
      this.body.add(bottomLeft.add([this.footSize.width, 0]));
      this.body.join(new this.paper.Path.Arc(bottomLeft.add([this.footSize.width, 0]), bottomLeft.add([this.footSize.width / 2, this.footSize.height / 2]), bottomLeft));
      this.body.closed = true;
      this.body.fillColor = this.bodyColor;
      this.face = new this.paper.Group();
      this.face.applyMatrix = false;
      this.forehead = new this.paper.Path.RoundRectangle([0, 0], this.foreheadSize, this.bodyCornerSize);
      this.forehead.fillColor = this.headColor;
      eyeSocketPosition = this.foreheadSize.subtract(this.eyeSocketSize).divide(2);
      this.eyeSocket = new this.paper.Path.RoundRectangle(eyeSocketPosition, this.eyeSocketSize, [this.eyeSocketSize.height / 2, this.eyeSocketSize.height / 2]);
      this.eyeSocket.applyMatrix = false;
      this.eyeSocket.fillColor = 'white';
      eyeOffset = this.eyeSocketSize.height / 2;
      pupilOffset = (this.eyeSize.width - this.pupilSize.width) / 2 * 0.4;
      this.leftEye = new this.paper.Group();
      this.leftEye.applyMatrix = false;
      this.leftEyeIris = new this.paper.Path.Circle(eyeSocketPosition.add([eyeOffset, eyeOffset]), this.eyeSize.divide(2));
      this.leftEyeIris.fillColor = this.eyeColor;
      this.leftEyePupil = new this.paper.Path.Circle(eyeSocketPosition.add([eyeOffset - pupilOffset, eyeOffset - pupilOffset]), this.pupilSize.divide(2));
      this.leftEyePupil.fillColor = 'white';
      this.leftEye.addChild(this.leftEyeIris);
      this.leftEye.addChild(this.leftEyePupil);
      this.rightEye = new this.paper.Group();
      this.rightEye.applyMatrix = false;
      this.rightEyeIris = new this.paper.Path.Circle(eyeSocketPosition.add([this.eyeSocketSize.width - eyeOffset, eyeOffset]), this.eyeSize.divide(2));
      this.rightEyeIris.fillColor = this.eyeColor;
      this.rightEyePupil = new this.paper.Path.Circle(eyeSocketPosition.add([this.eyeSocketSize.width - eyeOffset - pupilOffset, eyeOffset - pupilOffset]), this.pupilSize.divide(2));
      this.rightEyePupil.fillColor = 'white';
      this.rightEye.addChild(this.rightEyeIris);
      this.rightEye.addChild(this.rightEyePupil);
      this.mouth = new this.paper.Path.RoundRectangle([0, this.foreheadSize.height - this.bodyCornerSize.height * 2], this.mouthSize.add([0, this.bodyCornerSize.height * 2]), this.bodyCornerSize);
      this.mouth.fillColor = this.mouthColor;
      this.chin = new this.paper.Path.RoundRectangle([0, this.faceSize.height - this.chinSize.height - this.bodyCornerSize.height * 2], this.chinSize.add([0, this.bodyCornerSize.height * 2]), this.bodyCornerSize);
      this.chin.fillColor = this.headColor;
      teeth = (function() {
        var j, len, ref, results;
        ref = this.teeth;
        results = [];
        for (i = j = 0, len = ref.length; j < len; i = ++j) {
          tooth = ref[i];
          if (tooth === 0) {
            continue;
          }
          height = this.toothHeight * tooth;
          toothShape = new this.paper.Path([0, 0], [this.width / this.teeth.length, 0], new this.paper.Segment([this.width / this.teeth.length, -height], null, [-this.width / this.teeth.length, -height * 0.2]));
          toothShape.closed = true;
          toothShape.fillColor = 'white';
          toothShape.translate(new this.paper.Point(this.width / this.teeth.length * i, 0));
          results.push(toothShape);
        }
        return results;
      }).call(this);
      this.teeth = new this.paper.Group(teeth);
      this.teeth.translate([0, this.faceSize.height - this.chinSize.height]);
      this.teethClipped = new this.paper.Group(this.mouth.clone(), this.teeth);
      this.teethClipped.clipped = true;
      this.lowerFace = new this.paper.Group(this.chin, this.mouth, this.teethClipped);
      this.face.addChild(this.lowerFace);
      this.upperFace = new this.paper.Group(this.forehead, this.eyeSocket, this.leftEye, this.rightEye);
      this.face.addChild(this.upperFace);
      this.face.translate(new this.paper.Point(0, 20));
      this.group.addChildren([this.body, this.face]);
      this.paper.view.on('frame', this.frame);
      this.oHeight = this.rightEye.bounds.height;
      this.rightEye.bounds.height = this.oHeight;
      blink = (function(_this) {
        return function() {
          if (Date.now() - _this.lastFrame > 400) {
            return _this.waiting.push(blink);
          }
          _this.eyeSocket.scale(1, 0.05);
          _this.leftEye.visible = false;
          _this.rightEye.visible = false;
          setTimeout(function() {
            _this.eyeSocket.scale(1, 20);
            _this.leftEye.visible = true;
            return _this.rightEye.visible = true;
          }, 100);
          return setTimeout(blink, 3000 + Math.random() * 6000);
        };
      })(this);
      getEyeTarget = (function(_this) {
        return function() {
          var x, y;
          if (Date.now() - _this.lastFrame > 400) {
            return _this.waiting.push(getEyeTarget);
          }
          _this.targetEyeRotation = Math.random() * 270 - 150;
          x = Math.cos(_this.targetEyeRotation / 180 * Math.PI) * (_this.eyeSize.width / 2 - _this.pupilSize.width / 2 - pupilOffset);
          y = Math.sin(_this.targetEyeRotation / 180 * Math.PI) * (_this.eyeSize.width / 2 - _this.pupilSize.width / 2 - pupilOffset);
          _this.pupilTargetPosition = new _this.paper.Point(x, y);
          return setTimeout(getEyeTarget, 500 + Math.random() * 5000);
        };
      })(this);
      this.faceBasePosition = this.face.position.clone();
      this.upperFaceBasePosition = this.upperFace.position.clone();
      this.lowerFaceBasePosition = this.lowerFace.position.clone();
      this.lowerFaceIntermediatePosition = this.lowerFace.position.clone();
      getLowerFaceTarget = (function(_this) {
        return function() {
          if (Date.now() - _this.lastFrame > 400) {
            return _this.waiting.push(getLowerFaceTarget);
          }
          if (_this.lowerFaceSequence) {
            _this.lowerFaceSequence.i++;
            if (_this.lowerFaceSequence.i >= _this.lowerFaceSequence.length) {
              _this.lowerFaceSequence = null;
              _this.upperFaceTargetPosition = _this.upperFaceBasePosition;
              _this.lowerFaceTargetPosition = _this.lowerFaceBasePosition;
            } else {
              if (modulo(_this.lowerFaceSequence.i, 2) === 1) {
                _this.upperFaceTargetPosition = _this.upperFaceBasePosition.add([0, 2]);
                _this.lowerFaceTargetPosition = _this.lowerFaceBasePosition.add([0, _this.lowerFaceSequence.max]);
              } else {
                _this.upperFaceTargetPosition = _this.upperFaceBasePosition.add([0, 0]);
                _this.lowerFaceTargetPosition = _this.lowerFaceBasePosition.add([0, _this.lowerFaceSequence.min]);
              }
            }
            return setTimeout(getLowerFaceTarget, 250);
          } else {
            _this.lowerFaceSequence = {
              length: Math.round(Math.random() * 3) + 3,
              min: 0,
              max: Math.max(-_this.mouthHeight + _this.toothHeight, -_this.foreheadSize.height + _this.bodyCornerSize.height * 2 + 2),
              i: 0
            };
            return setTimeout(getLowerFaceTarget, 4000 + Math.random() * 12000);
          }
        };
      })(this);
      this.waiting = [];
      blink();
      getLowerFaceTarget();
      getEyeTarget();
      this;
    }

    Monster.prototype.frame = function(e) {
      var fn, j, len, pos, ref, vector;
      this.lastFrame = Date.now();
      if (this.waiting.length) {
        ref = this.waiting;
        for (j = 0, len = ref.length; j < len; j++) {
          fn = ref[j];
          fn.apply(this);
        }
        this.waiting = [];
      }
      pos = Math.cos((modulo(e.time, 4)) / 2 * Math.PI);
      this.face.scaling.x = pos * 0.01 + 0.99;
      this.body.scaling.x = pos * 0.01 + 0.99;
      if (this.lowerFaceTargetPosition) {
        vector = this.lowerFaceTargetPosition.subtract(this.lowerFaceIntermediatePosition);
        if (vector.length > 2) {
          this.lowerFaceIntermediatePosition = this.lowerFaceIntermediatePosition.add(vector.divide(2));
          this.lowerFace.position = this.lowerFaceIntermediatePosition.clone();
        }
        vector = this.upperFaceTargetPosition.subtract(this.upperFace.position);
        if (vector.length > 0.1) {
          this.upperFace.position = this.upperFace.position.add(vector.divide(2));
        }
      } else {
        this.lowerFace.position = this.lowerFaceIntermediatePosition;
      }
      pos = -1 * (Math.cos((modulo(e.time, 4)) / 2 * Math.PI));
      this.lowerFace.position.y = this.lowerFaceIntermediatePosition.y + 1 * pos;
      vector = this.pupilTargetPosition.subtract(this.rightEyePupil.position).add(this.rightEye.position);
      if (vector.length > 2) {
        this.leftEyePupil.position = this.leftEyePupil.position.add(vector.divide(30));
        return this.rightEyePupil.position = this.rightEyePupil.position.add(vector.divide(30));
      }
    };

    return Monster;

  })();

}).call(this);
